@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-primary;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-bold;
  }
  a,
  p {
    @apply text-xs leading-6;
  }


  .active {
    @apply font-bold;
  }

  h1 {
    @apply text-2xl uppercase leading-8;
  }

  h2 {
    @apply text-xl leading-[30px];
  }

  a {
    @apply hover:underline;
  }

  /* INPUT CLASSES */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
}

/* SCROLLBAR CLASSES */
/* width */
#scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

/* Track */
#scroll::-webkit-scrollbar-track {
  background: white;
  border: 1px solid #1c67ad;
}

/* Handle */
#scroll::-webkit-scrollbar-thumb {
  background: #1c67ad;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
